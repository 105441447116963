import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import { Layout } from "../layout";
import { useMediaQuery } from "@material-ui/core";
import BlockContent from "@sanity/block-content-to-react";
import Button from "../components/Button";

const useProductPageStyles = makeStyles({
    container: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit,minmax(240px,1fr))",
        marginTop: "2rem",
        width: "95%",
        //justifyContent: "space-evenly",
        margin: "auto",
        fontSize: "1rem",
    },
    imageContainer: (phoneMatches) => ({
        maxWidth: phoneMatches ? "100vw" : "480px",
        width: phoneMatches ? "85%" : "40vw",
        margin: "auto",
    }),
    image: {
        //width: "50vw",
        //maxWidth: "500px",
        // height : "100vh",
        // maxWidth: "800px",
        // maxHeight: "800px",
        width: "100%",
        minWidth: "600px",
        // maxHeight: "100%",
    },
    gallery: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
        padding: "1rem 0rem 1rem 0rem",
        gridGap: "20px",
        maxWidth: "1800px",
        width : "90%",
        margin: "auto",
    },

    productName: {
        fontSize: "1.5em",
        fontWeight: 500,
    },
    categoryName: {
        fontSize: "0.8em",
    },
    description: {
        fontSize: "1em",
        fontWeight: 600,
    },
    descriptionContainer: {
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "1.4em",
        lineHeight: 1.5,
    },
    mainContainer: (phoneMatches) => ({
        fontSize: phoneMatches ? "2rem" : "1rem",
    }),
    block: {
        "& > h2": {
            textDecoration: "none",
            fontWeight: 400,
            fontSize: "1em",
        },
        "& > *": {
            fontSize: "0.7em",
        },
        "& > ul": {
            paddingInlineStart: "20px",
        },
    },
    button: {
        fontSize: "0.8em",
        fontWeight: 600,
    },
});

const ProductPage = (props) => {
    const phoneMatches = useMediaQuery("(max-width: 768px)");
    const classes = useProductPageStyles({ phoneMatches });
    console.log(props);
    const {
        thumbnail: thumbnailData,
        product,
        slug,
        Categories,
        _rawDescription,
        images: imagesData,
        id,
    } = props.pageContext;
    const categories = Categories.map((category) => category.title);
    const mainCategory = categories[0];
    const thumbnail = thumbnailData.asset.gatsbyImageData;
    const noImage = imagesData == null || imagesData.length === 0;
    const blocks = _rawDescription;
    let images, imageComponents;
    if (!noImage) {
        images = imagesData.map((image) => image.asset.gatsbyImageData);
        imageComponents = images.map((gatsbyImageData) => (
            <div className={classes.imageContainer}>
                <GatsbyImage
                    className={classes.image}
                    image={gatsbyImageData}
                    alt="alternative product image"
                    imgStyle={{ objectFit: "contain" }}
                />
            </div>
        ));
    }
    const matches = useMediaQuery("(min-width:600px)");
    return (
        <Layout>
            <div
                style={{
                    display: "flex",
                    maxWidth: "1800px",
                    margin: "auto",
                    flexDirection: matches ? "row" : "column",
                }}
                className={classes.mainContainer}
            >
                <div
                    style={{
                        marginLeft: !matches ? "auto" : "1rem",
                        marginRight: !matches ? "auto" : "1rem",
                        width: !matches ? "90%" : "auto",
                        // maxWidth : "1300px"
                    }}
                >
                    <div className={classes.imageContainer}>
                        <GatsbyImage
                            image={thumbnail}
                            className={classes.image}
                            alt={`thumbnail of ${product}`}
                            imgStyle={{ objectFit: "contain" }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        fontSize: phoneMatches ? "1.2rem" : "2.5vmin",
                        maxWidth: "1000px",
                        margin: "auto",
                        width: "90%",
                        marginTop: phoneMatches ? "2rem" : "0rem",
                    }}
                >
                    <div className={classes.categoryName}>{mainCategory}</div>
                    <div className={classes.productName}>{product}</div>
                    <Link to="/contact-us" state={{ product }}>
                        <Button className={classes.button}>
                            Request A Quote
                        </Button>
                    </Link>
                    <div className={classes.descriptionContainer}>
                        <BlockContent
                            blocks={blocks}
                            className={classes.block}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.gallery}>{imageComponents}</div>
        </Layout>
    );
};
export default ProductPage;
